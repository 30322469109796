import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import LayoutContentWrapper from "../../ContentWrapper";
import { HEADER_HEIGHT } from "../../HeaderComponent/constants";
import HorizontalRule from "../../HorizontalRule";

const Wrapper = styled.section`
  position: relative;
`;

const StyledLayoutContentWrapper = styled(LayoutContentWrapper)`
  margin-top: ${HEADER_HEIGHT}px;
  margin-bottom: 0;
`;

const TitleWrapper = styled.div`
  padding: ${theme.space("m")} 0;
`;

const BottomWrapper = styled.div`
  padding-bottom: ${theme.space("m")};

  ${mq("2")} {
    display: flex;
    justify-content: space-between;
  }
`;

const IntroductionWrapper = styled.div`
  ${mq("2")} {
    flex: 1 1 50%;
    margin-right: ${theme.space("xl")};
  }

  > * {
    margin-top: 0;
  }
`;

const ContentWrapper = styled.div`
  ${mq("2")} {
    flex: 1 1 50%;

    > * {
      margin-top: 0;
    }
  }
`;

function HeroV2({ title, content, introduction, ...props }) {
  return (
    <Wrapper {...props}>
      <StyledLayoutContentWrapper columns={10}>
        <TitleWrapper>{title}</TitleWrapper>
        <BottomWrapper>
          {introduction && (
            <IntroductionWrapper>
              {introduction}
              <HorizontalRule />
            </IntroductionWrapper>
          )}
          <ContentWrapper>{content}</ContentWrapper>
        </BottomWrapper>
      </StyledLayoutContentWrapper>
    </Wrapper>
  );
}

HeroV2.propTypes = {
  title: PropTypes.node.isRequired,
  introduction: PropTypes.node,
  content: PropTypes.node,
};

HeroV2.defaultProps = {};

export default HeroV2;
