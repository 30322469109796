import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import ReplaceableLink from "../../components/ReplaceableLink";
import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Button from "../Button";
import Icon from "../Icon";

const Wrapper = styled.div`
  margin-top: ${theme.space("l")};
  padding-bottom: ${theme.space("l")};
  border-bottom: ${theme.border()};
`;

const Flex = styled.div`
  ${mq("2")} {
    display: flex;
  }
`;

const TitleWrapper = styled.div`
  flex: 0 0 38%;
  margin-bottom: ${theme.space("s")};
  padding-right: ${theme.space("m")};
`;

const TextWrapper = styled.div`
  * {
    margin-top: 0;
  }
`;

function VacancyItem({ title, text, url, as, ...props }) {
  return (
    <Wrapper {...props}>
      <Flex>
        <TitleWrapper>{title}</TitleWrapper>
        <TextWrapper>
          {text}
          <ReplaceableLink url={url} as={as}>
            <Button styling="link" as="span">
              <span>Meer informatie</span>
              <Icon type="arrow" />
            </Button>
          </ReplaceableLink>
        </TextWrapper>
      </Flex>
    </Wrapper>
  );
}

export const vacancyitem = {
  title: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  as: PropTypes.elementType,
};

VacancyItem.propTypes = vacancyitem;

VacancyItem.defaultProps = {};

export default VacancyItem;
