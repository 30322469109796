import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import Vacancyitem, { vacancyitem } from "./VacancyItem";

const ItemWrapper = styled.div`
  padding-top: ${({ hasTitle }) => (hasTitle ? theme.space("m") : 0)};

  > *:last-of-type {
    border-bottom: none;
  }
`;

function VacancyList({ title, items, ...props }) {
  return (
    <div>
      {title}
      <ItemWrapper hasTitle={!!title} {...props}>
        {items.map(({ id, ...item }) => (
          <Vacancyitem key={id} {...item} />
        ))}
      </ItemWrapper>
    </div>
  );
}

VacancyList.propTypes = {
  title: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      ...vacancyitem,
    })
  ),
};

VacancyList.defaultProps = {};

export default VacancyList;
