import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { map, prop } from "ramda";
import React from "react";

import ContentWrapper from "../components/ContentWrapper";
import Heading from "../components/Heading";
import Hero from "../components/Hero/v2";
import Introduction from "../components/Introduction";
import ImageCarousel from "../components/Modular/ImageCarousel";
import SEO from "../components/SEO";
import Text from "../components/Text";
import VacancyList from "../components/VacancyList";
import VerticalMargin from "../components/VerticalMargin";
import { workWith } from "../types";

const vacanciesMap = (services) =>
  map(
    ({ id, jobTitle, externalLink, description }) => ({
      id,
      title: (
        <Heading size={3} color="primary">
          {jobTitle}
        </Heading>
      ),
      text: <Text>{description}</Text>,
      url: externalLink,
    }),
    services
  );

const WerkenBij = ({
  data: {
    data: { workAt },
  },
}) => (
  <>
    <SEO tags={prop("seoMetaTags", workAt)} />

    <Hero
      title={<Heading size={1}>{prop("title", workAt)}</Heading>}
      introduction={<Introduction>{prop("tagline", workAt)}</Introduction>}
      content={<Text>{prop("description", workAt)}</Text>}
    />

    <ImageCarousel carouselImages={prop("carouselImages", workAt)} />

    <VerticalMargin>
      <ContentWrapper columns={10}>
        <VacancyList
          title={<Heading size={2}>{prop("vacanciesTitle", workAt)}</Heading>}
          items={vacanciesMap(prop("vacancies", workAt))}
        />
      </ContentWrapper>
    </VerticalMargin>
  </>
);

WerkenBij.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      workAt: PropTypes.shape(workWith),
    }),
  }),
};

export default WerkenBij;

export const query = graphql`
  query WorkAtQuery {
    data: dato {
      workAt {
        seoMetaTags: _seoMetaTags {
          tag
          attributes
          content
        }
        title
        tagline
        description
        vacanciesTitle
        carouselImages {
          data: responsiveImage(
            imgixParams: { fit: crop, h: 520, w: 848, fm: jpg, auto: format }
          ) {
            ...responsiveImageFragment
          }
          title
        }
        vacancies {
          id
          jobTitle
          externalLink
          description
          moreInfoLabel
        }
      }
    }
  }
`;
